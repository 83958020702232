

footer{
	bottom: 0;
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;

	margin-top: 40px;
	margin-bottom: 100px;

}


footer p{
	text-align: center;
	color: rgb(150,150,150);
}

.strong {
	font-weight: bold;
	text-transform: capitalize;
}